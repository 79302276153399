<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">订单管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">订单列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="searchData.courseName"
                type="text"
                size="small"
                clearable
                placeholder="请输入课程名称"
              />
            </div>
            <div title="订单状态" class="searchboxItem ci-full">
              <span class="itemLabel">订单状态:</span>
              <el-select
                v-model="searchData.orderStatus"
                placeholder="请选择订单状态"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in orderStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="订单日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 6rem">订单日期:</span>
              <el-date-picker
                  clearable
                  style="width: 260px"
                  size="small"
                  format="yyyy-MM-dd"
                  v-model="searchData.orderTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <el-button
              style="margin-left: 20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column label="课程封面" align="center" width="260px">
                <template slot-scope="scope" class="flexcc">
                  <img
                    style="width: 250px; height: 128px; border-radius: 10px"
                    :src="scope.row.logo"
                    alt=""
                  />
                </template>
              </el-table-column>
              <el-table-column
                label="课程名称"
                align="center"
                prop="productName"
                show-overflow-tooltip
                width="160"
              />

              <el-table-column
                label="课时"
                align="center"
                prop="lessonNum"
                show-overflow-tooltip
                width="100"
              />

              <el-table-column
                label="单价(元)"
                align="center"
                prop="productPrice"
                show-overflow-tooltip
                width="120"
              />
              <el-table-column
                label="数量"
                align="center"
                prop="productCnt"
                show-overflow-tooltip
                width="100"
              />
              <el-table-column
                label="支付方式"
                align="center"
                prop="paymentMethod"
                show-overflow-tooltip
                width="100"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.paymentMethod == 3">微信</span>
                  <span v-else-if="scope.row.paymentMethod == 4">支付宝</span>
                  <span v-else-if="scope.row.paymentMethod == 5">银行对公</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column
                label="订单编号"
                align="center"
                prop="orderSn"
                show-overflow-tooltip
                width="160"
              />
              <el-table-column
                label="订单日期"
                align="center"
                prop="createTime"
                show-overflow-tooltip
                width="140"
              />
              <el-table-column
                label="交易状态"
                align="center"
                prop="orderStatus"
                show-overflow-tooltip
                width="120"
              >
                <template slot-scope="scope" class="flexcc">
                  {{ $setDictionary("ORDER_STATUS", scope.row.orderStatus) }}
                </template>
              </el-table-column>
              <el-table-column
                label="课程来源"
                align="center"
                prop="compName"
                show-overflow-tooltip
                width="160"
              />
              <el-table-column
                label="订单金额"
                align="center"
                prop="orderMoney"
                show-overflow-tooltip
                width="100"
              >
                <template slot-scope="scope" class="flexcc">
                  <span style="color: red; font-size: 16px; font-weight: 600"
                    >￥{{ scope.row.orderMoney }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="240px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    @click="seeCurriculumInfo(scope.row)"
                    >查看课程</el-button
                  >
                  <el-button
                    v-if="scope.row.orderStatus == '0'"
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    @click="cancellationOfOrder(scope.row)"
                    >取消订单</el-button
                  >
                  <el-button
                    v-if="
                      scope.row.orderStatus == '0' &&
                        scope.row.paymentMethod != '5'
                    "
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    @click="continueToPay(scope.row)"
                    >继续支付</el-button
                  >
                  <el-button
                    v-if="scope.row.orderStatus == '1' || scope.row.orderStatus == '5'"
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    @click="refund(scope.row)"
                    >退款</el-button
                  >
                  <el-button
                    v-if="
                      scope.row.orderStatus == 0 &&
                        scope.row.paymentMethod == '5'
                    "
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    @click="uploadVoucher(scope.row.orderId)"
                    >上传凭证</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
        <PartnerCourseTable ref="PartnerCourseTable" />
      </div>
    </div>
    <el-dialog
      title="继续支付"
      :visible.sync="weixindialogVisible"
      :before-close="closeweixindialogVisible"
      width="35%"
    >
      <div class="jxzf_1">课程名称：{{ jxzfLjData.jxzf_1 }}</div>
      <div class="jxzf_2">
        订单支付金额：<span>￥{{ jxzfLjData.jxzf_2 }}</span>
      </div>
      <div class="jxzf_3">订单时间：{{ jxzfLjData.jxzf_3 }}</div>
      <div id="qrcode" ref="qrcode"></div>
      <div class="jxzf_4">
        请打开微信 - 扫一扫<br />
        扫描二维码完成付款
      </div>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button class="bgc-bv" size="small" @click="closeweixindialogVisible"
          >取 消</el-button
        >
      </div>
    </el-dialog>
    <!-- 上传附件 - loading -->
    <el-dialog
      title="上传凭证"
      :visible.sync="uploadfiledialog"
      width="38%"
      class="toVoidLoding"
    >
      <div style="margin: 10 0 10px;display: flex;flex-direction: column;">
        <h2>请您将电汇(对公支付)汇款成功的凭证或付款订单页面截图或下载pdf。</h2>
        <el-upload
          class="upload-demo upload-btn upload-btndrag"
          :action="actionUrl"
          :on-error="handleError"
          :on-success="handleSuccess"
          :on-change="uploadChange1"
          :show-file-list="false"
          :auto-upload="false"
          drag
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <p style="color:red;font-size:1rem">注意:上传的凭证要求格式为pdf或jpg、png图片清晰,能清楚反应您付款的情况。</p>
        <div v-if="fileName" style="margin-top: 10px; margin-left: 10px">
          当前选择文件：
          <div style="display: flex; flex-direction: column">
            <span style="color: #409eff;margin-top:10px">
              {{ fileName }}
              <!-- <a @click="reomveExl" style="color: #5bb5ff;margin:0 15px">预览</a> -->
              <a
                @click="lookExl(voucherUrl, extension)"
                style="color: #409eff; margin: 0 15px"
                >预览</a
              >
              <!-- <a @click="reomveExl()" style="color: red; margin: 0 15px"
                >删除</a
              > -->
            </span>
          </div>
        </div>
        <div v-else>
          <p style="margin-top: 10px; margin-left: 10px">未选择文件</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          class="bgc-bv"
          round
          @click="uploadfileClose"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          class="bgc-bv"
          round
          @click="uploadfileDetermine"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 预览附件 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="previewLoding1"
      @close="uploadLodClose"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert1"
          style="height: 600px"
          v-if="extension == 'PDF' || extension == 'pdf'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import QRCode from "qrcodejs2"; // 引入qrcode
import pdf from "pdfobject";
export default {
  name: "Partner/partnerList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    let currentDate = new Date();
    let startDate = currentDate.getFullYear() + '-01-01';
    let endMonth = currentDate.getMonth() + 1;
    let endDay = currentDate.getDate();
    if (endMonth < 10) {
      endMonth = "0" + endMonth;
    }
    if (endDay < 10) {
      endDay = "0" + endDay;
    }
    let endDate = currentDate.getFullYear() + "-" + endMonth + "-" + endDay;
    return {
      // 检索数据
      searchData: {
        orderTime: [startDate, endDate],
        courseName: "", // 课程名称
        orderStatus: "", // 订单状态
      },
      // 订单状态 - 下拉数据
      orderStatusList: [],
      // 订单编号
      orderSn: "",
      // 微信支付弹框
      weixindialogVisible: false,
      // 实时获取购买是否成功
      getInfosSetInterval: null,
      // 继续支付 - 微信：显示友好的字段
      jxzfLjData: {
        jxzf_1: "",
        jxzf_2: "",
        jxzf_3: "",
      },
      uploadfiledialog: false,
      fileName: "",
      voucherUrl: "",
      voucherKey: "",
      extension: "", //上传类型
      ImgSrc: "", //预览图片Src路径
      previewLoding1:false
    };
  },
  created() {
    this.getOrderStatusList();
    this.getTableHeight();
  },
  methods: {
    // 获取 - 购买状态下拉码值
    getOrderStatusList() {
      const evaluateCertPaymentList = this.$setDictionary(
        "ORDER_STATUS",
        "list"
      );
      const CertPaymentList = [];
      for (const key in evaluateCertPaymentList) {
        CertPaymentList.push({
          value: key,
          label: evaluateCertPaymentList[key],
        });
      }
      this.orderStatusList = CertPaymentList;
    },
    // 获取 - 订单列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.courseName) {
        params.courseName = this.searchData.courseName;
      }
      if (this.searchData.orderStatus) {
        params.orderStatus = this.searchData.orderStatus;
      }
      if (this.searchData.orderTime && this.searchData.orderTime.length >= 1
        && this.searchData.orderTime[0]) {
        params.startDate = this.searchData.orderTime[0];
      }
      if (this.searchData.orderTime && this.searchData.orderTime.length >= 2
          && this.searchData.orderTime[1]) {
        params.endDate = this.searchData.orderTime[1];
      }
      this.doFetch(
        {
          url: "/order/drp-order-main/hospital/list",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 查看课程
    seeCurriculumInfo(row) {
      this.$router.push({
        path: "/web/coursesIboughtInfo",
        query: {
          courseId: row.courseId,
          projectCourseId: row.projectCourseId,
        },
      });
    },
    // 取消订单
    cancellationOfOrder(row) {
      this.$post(
        "/order/drp-order-main/close",
        {
          orderSn: row.orderSn,
        },
        true,
        3000,
        2
      ).then((res) => {
        if (res.status == "0") {
          this.$message({
            message: "该订单已关闭",
            type: "success",
          });
          this.getData();
        }
      });
    },
    // 继续支付
    continueToPay(row) {
      // 微信
      if (row.paymentMethod == 3) {
        this.weixindialogVisible = true;
        this.$post(
          "/wechat/pay/repay",
          {
            orderSn: row.orderSn,
          },
          3000,
          true,
          2
        ).then((res) => {
          this.orderSn = row.orderSn;
          this.jxzfLjData.jxzf_1 = row.productName;
          this.jxzfLjData.jxzf_2 = row.orderMoney;
          this.jxzfLjData.jxzf_3 = row.createTime;
          this.crateQrcode(res.data.codeURL);
        });
      }
      // 支付宝
      if (row.paymentMethod == 4) {
        const baseURL =
          process.env.VUE_APP_URL == "development"
            ? "http://192.168.3.112:9000/question-lib"
            : process.env.NODE_ENV == "development"
            ? "http://192.168.3.112:9000/question-lib"
            : "https://anzhi.bjsrxx.com/question-lib";
        window.location.href =
          baseURL +
          "/ali/pay/repay?orderSn=" +
          row.orderSn +
          "&token=" +
          this.$store.state.token +
          "&returnUrl=" +
          encodeURIComponent(
            location.protocol +
              "//" +
              location.host +
              "/#/web/courseCenterInfo/paySuccess"
          );
      }
    },
    // 生成二维码
    crateQrcode(codeURL) {
      this.$refs.qrcode.innerHTML = "";
      this.qr = new QRCode("qrcode", {
        width: 150,
        height: 150, // 高度
        text: codeURL, // 二维码内容
        render: "canvas", // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
      });
      this.getInfosSetInterval = setInterval(() => {
        this.getInfos();
      }, 3000);
    },
    // 实时获取购买是否成功
    getInfos() {
      this.$post(
        "/order/drp-order-main/detail",
        {
          orderSn: this.orderSn,
        },
        3000,
        true,
        2
      ).then((res) => {
        if (res.data.orderStatus == 1) {
          this.weixindialogVisible = false;
          clearInterval(this.getInfosSetInterval); // 清除定时器
          this.getInfosSetInterval = null;
          let jsonData = JSON.stringify(res.data);
          this.$router.push({
            path: "/web/courseCenterInfo/paySuccess",
            query: {
              jsonData,
            },
          });
        }
      });
    },
    // 取消微信支付
    closeweixindialogVisible() {
      this.weixindialogVisible = false;
      clearInterval(this.getInfosSetInterval); // 清除定时器
      this.getInfosSetInterval = null;
    },
    // 退款
    refund(row) {
      this.$post(
        "/order/drp-order-main/refund/apply",
        {
          orderSn: row.orderSn,
        },
        true,
        3000,
        2
      )
        .then((res) => {
          if (res.status == "0") {
            this.$message({
              message: "您已成功申请退款",
              type: "success",
            });
            this.getData();
          } else {
            this.$message({
              message: res.data.message,
              type: "warning",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "warning",
          });
        });
    },
    //上传附件
    uploadVoucher(orderId) {
      this.uploadfiledialog = true;
      this.orderId = orderId;
      this.getfileList(orderId);
    },
    uploadChange1(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      this.extension =
        (extension != "pdf" && extension != "PDF" ) ? "IMAGE" : "PDF";
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";
      const jpg = extension === "jpg";
      const png = extension === "png";
      if (!isXLSX && !isxlsx && !jpg && !png) {
        this.$message.error("只能上传后缀是.pdf或.PDF或.jpg或.png的文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.voucherUrl = result.data.fileURL;
          this.voucherKey = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    // 上传附件 - 取消
    uploadfileClose() {
      this.uploadfiledialog = false;
      this.fileName = "";
      this.voucherKey = "";
      this.voucherUrl = "";
    },
    // 上传附件 - 确定
    uploadfileDetermine() {
      let parmar = {
        orderId: this.orderId,
        fileType: this.extension,
        voucherKey: this.voucherKey,
        voucherName: this.fileName,
      };
      this.$post("/biz/order/voucher/save", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.uploadfiledialog = false;
            this.fileName = "";
            this.voucherKey = "";
            this.voucherUrl = "";
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
    //上传附件 - 回显
    getfileList(orderId) {
      this.$post("/biz/order/voucher/getInfo", { orderId })
        .then((ret) => {
          this.voucherKey = ret.data.voucherKey || '';
          this.voucherUrl = ret.data.voucherUrl || '';
          this.fileName = ret.data.voucherName || '';
          this.extension = ret.data.fileType || '';
        })
        .catch((err) => {
          return;
        });
    },
    //上传附件 - 移除
    reomveExl() {
      this.fileName = "";
      this.voucherKey = "";
      this.voucherUrl = "";
      this.$forceUpdate();
    },
    //上传附件 - 查看
    lookExl(voucherUrl, extension) {
      console.log(voucherUrl,extension);
      this.previewLoding1 = true;
      if (extension == "PDF") {
        this.$nextTick(() => {
          pdf.embed(voucherUrl, "#pdf-cert1");
        });
      } else {
        this.ImgSrc = voucherUrl;
      }
    },
    //预览图片pdf关闭
    uploadLodClose() {
      this.previewLoding1 = false;
      this.ImgSrc = "";
    },
    // 申请开票
    applyForInvoicing(row) {},
    // 列表高度计算
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  // 离开这个页面也要清除
  beforeDestroy() {
    clearInterval(this.getInfosSetInterval); // 清除定时器
    this.getInfosSetInterval = null;
  },
  watch: {},
};
</script>
<style lang="less" scoped>
#qrcode {
  width: 150px;
  height: 150px;
  margin: auto;
  margin-top: 20px;
}
.jxzf_1 {
  font-size: 16px;
  margin-bottom: 10px;
}
.jxzf_2 {
  span {
    color: red;
    font-size: 16px;
    font-weight: 600;
  }
  margin-bottom: 10px;
}
.jxzf_3 {
  margin-bottom: 20px;
}
.jxzf_4 {
  text-align: center;
  margin-top: 20px;
  color: #8b8b8b;
}
.el-upload {
  height: 180px !important;
}
.upload-btn {
  height: 180px;
}
</style>
